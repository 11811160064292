export class Appointment {
  assigned_uid: string;
  author_uid: string;
  create_ip: string;
  doctor_note: string;
  thread_id: string;
  date: number;
  minutes: number;
  type?: string;
  id?: string;
  completed?: boolean;
  pre_meeting?: boolean;
  assigned?: boolean;
}
