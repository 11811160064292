import { User } from '@core/auth/_models/user.model';
import { UserStore } from '@core/store/user.store';
import { UserService } from '@core/services/user.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {SwalProvider} from '@core/providers/swal.provider';

@Component({
  selector: 'kt-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  type = 1;

  message = '';
  subject = '';

  user: User;

  constructor(
    private userService: UserService,
    private store: UserStore
  ) { }

  ngOnInit() {
    this.user = this.store.getValue().user;
  }

  submit() {
    if (!this.validate) {
      return;
    }
    const data = {
      author: {
        display_name: this.user.is_doctor ? this.user.firstname + ' ' + this.user.lastname : this.user.display_name,
        profile_image: this.user.profile_image,
        is_doctor: this.user.is_doctor,
        uid: this.user.uid,
      },
      message: this.message,
      subject: this.subject,
      date: new Date().getTime(),
      type: this.type
    };
    this.userService.sendContact(data).then(() => {
      this.message = '';
      this.subject = '';
      SwalProvider.fire('Başarılı!', 'Mesajın gönderildi!', 'success');
    }).catch(() => {
      SwalProvider.fire('Hata', 'Bilinmeyen bir hata oluştu.', 'error');
    });
  }

  public get validate() {
    return this.message.trim().length > 10 && this.subject.trim().length > 3;
  }
}
