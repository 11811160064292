export class Package {
    id?: string;
    author: {
        uid: string;
        lastname: string;
        firstname: string;
        pre_meeting: boolean;
        nearest_appointment_date: number;
        average_point: number;
        profile_image: string;
    };
    meetings: number;
    packs: {
        months: number;
        price: number;
    }[];

    constructor() {
        const arr = [0, 1, 2, 3, 4, 5];
        this.id = null;
        this.author = null;
        this.meetings = null;
        this.packs = arr.map((el, i) => ({ months: i + 1, price: 0 }));
    }
}
