import { UserStore } from '@core/store/user.store';
import { Router } from '@angular/router';
import { Appointment } from '@core/models/appointment.model';
import { Component, OnInit, Input } from '@angular/core';
import {UserService} from '@core/services/user.service';
import {Observable} from 'rxjs';
import {User} from '@core/auth/_models/user.model';

@Component({
  selector: 'kt-incoming-appointment',
  templateUrl: './incoming-appointment.component.html',
  styleUrls: ['./incoming-appointment.component.scss']
})
export class IncomingAppointmentComponent implements OnInit {

  @Input() appointment: Appointment;

  doctor: Observable<User>;
  patient: Observable<User>;

  isDoctor: boolean;

  constructor(
    private router: Router,
    private store: UserStore,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.isDoctor = this.store.getValue().user.is_doctor;

    this.doctor = this.userService.getUser(this.appointment.author_uid);
    this.patient = this.userService.getUser(this.appointment.assigned_uid);
  }

  join() {
    this.router.navigate(['/chat/' + this.appointment.thread_id + '/' + this.appointment.type]);
  }

}
