import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from './feedback.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FeedbackComponent],
  exports: [
    FeedbackComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbButtonsModule,
  ]
})
export class FeedbackModule { }
