import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncomingAppointmentComponent } from './incoming-appointment.component';

@NgModule({
  declarations: [
    IncomingAppointmentComponent
  ],
  exports: [
    IncomingAppointmentComponent
  ],
  imports: [
    CommonModule
  ]
})
export class IncomingAppointmentModule { }
